import classes from '../../page.module.css';
import { HOData } from '../../../types/hoDataTypes';
import { isScarTeam } from '../../../util/propertyUtil.jsx';
import { default as classnames } from 'classnames';
import React from 'react';

interface QVFourCoverPageInfoProps {
  homeownerData: HOData;
  // specialBrokerageURL: string;
  //onClick: React.MouseEventHandler<HTMLButtonElement>;

  legalModalFunc: (open: any) => void;
  // legalModalFunc?: (e: MouseEvent<HTMLButtonElement>) => void;
}

const QVFourCoverPageInfo: React.FC<QVFourCoverPageInfoProps> = ({ homeownerData, legalModalFunc }): JSX.Element => {
  const phoneCircleDivider = (num: string, nameLength?: number) => {
    return (
      <div className={classnames(classes.phoneNumRow, nameLength && nameLength > 14 && classes.small)}>
        {num.slice(0, 3)}
        <div className={classes.phoneCircleDivider} />
        {num.slice(3, 6)} <div className={classes.phoneCircleDivider} />
        {num.slice(6)}
      </div>
    );
  };

  const formatAgentDesignation = (designation: string) => {
    if (designation === 'Realtor') {
      return `${designation}®`;
    } else {
      return `${designation}`;
    }
  };

  let {
    listgenUserFirstName,
    listgenUserLastName,
    mobilePhoneNo,
    agentLicenseNumber,
    specialBrokerageURL,
    agentDesignation,
    brokerageName,
    listgenUserID,
    isMemberOfTeam,
    teamMembers,
  } = homeownerData;
  // let specialBrokerageURL = homeownerData;
  let footerBrokerageStyle = '';
  // if (listgenUserID === '100190') {
  //   isMemberOfTeam = false;
  // }

  if (brokerageName.includes("Sotheby's")) {
    footerBrokerageStyle = 'footerLGBlue';
  } else if (
    (brokerageName.includes('Over') && brokerageName.includes('Moon')) ||
    brokerageName.includes('Compass') ||
    brokerageName.includes('Alemann & Associates')
  ) {
    footerBrokerageStyle = 'footerBlack';
  } else if (brokerageName.includes('Berkshire Hathaway')) {
    footerBrokerageStyle = 'footerPurple';
  } else if (brokerageName.includes('Village Properties')) {
    footerBrokerageStyle = 'footerHarbor';
  } else if (brokerageName.includes('Town')) {
    footerBrokerageStyle = 'footerLightBlue';
  }
  let alternateBrokerageUrl = {
    compass: 'https://pma-coverphotos.s3.amazonaws.com/Horizontal_White_Compass%20Logo_500px.png',
    cb: 'https://pma-coverphotos.s3.amazonaws.com/cb-stacked-brokerage-logo.png',
    'village-properties': 'https://pma-coverphotos.s3.amazonaws.com/village-properties-logo2.png',
    intero: 'https://pma-coverphotos.s3.amazonaws.com/intero-cover-logo.png',
    berkshire: 'https://pma-coverphotos.s3.amazonaws.com/bhhs-stacked-cover-logo.png',
    sothebys: 'https://pma-coverphotos.s3.amazonaws.com/sothebys-brokerage-logo.png',
  };

  let defaultLogo;
  if (brokerageName.includes('Compass')) {
    defaultLogo = alternateBrokerageUrl['compass'];
  } else if (brokerageName.includes('Coldwell Banker')) {
    defaultLogo = alternateBrokerageUrl['cb'];
  } else if (brokerageName.includes('Village Properties')) {
    defaultLogo = alternateBrokerageUrl['village-properties'];
  } else if (brokerageName.includes('Intero')) {
    defaultLogo = alternateBrokerageUrl['intero'];
  } else if (brokerageName.includes('Berkshire Hathaway')) {
    defaultLogo = alternateBrokerageUrl['berkshire'];
  } else if (brokerageName.includes('Sotheby')) {
    defaultLogo = alternateBrokerageUrl['sothebys'];
  }

  if (!specialBrokerageURL) {
    specialBrokerageURL = defaultLogo;
  }

  const camelCase = (str: string) => {
    let splitStr = str.split('-');
    let casedStr = [];
    for (let i = 0; i < splitStr.length; i++) {
      let word = splitStr[i];
      if (i >= 1) {
        const cap = word.charAt(0).toUpperCase() + word.slice(1);
        casedStr.push(cap);
      } else {
        casedStr.push(word);
      }
    }
    let joinedClass = casedStr.join('');
    return joinedClass;
  };

  const formattedSplit = (sliced: string) => {
    let transformed = sliced && sliced.slice();

    if (sliced) {
      if (sliced && sliced.includes(' ')) {
        transformed = sliced.split(' ')[0];
      }

      if (transformed && transformed.includes('%20')) {
        transformed = transformed.split('%20')[0];
      }
    } else {
      return 'defaultLogo';
    }
    return transformed;
  };
  let slicedLogo = specialBrokerageURL ? camelCase(specialBrokerageURL.slice(41, -4)) : defaultLogo ? defaultLogo : '';
  // let slicedLogo = specialBrokerageURL && specialBrokerageURL.slice(41, -4);
  // let logoStyle = classes.slicedLogo as
  // console.log('logoStyle', logoStyle);

  let agentLogoStyles = '';
  // console.log('specialBrokerageURL', specialBrokerageURL);

  agentLogoStyles = formattedSplit(slicedLogo);

  let agentInfoBoxStyles;
  if (brokerageName === 'intero') {
    agentInfoBoxStyles = 'larger-agent-info-box-width';
  }
  const isScar = isScarTeam(listgenUserID);
  if (isScar) {
    isMemberOfTeam = false;
  }
  const isAnnAlbanese = listgenUserID === '100063';
  const isJohnsons = listgenUserID === '100988';
  const isAmberRussell = listgenUserID === '100040' && isMemberOfTeam;

  if (isAmberRussell) {
    agentDesignation = 'Broker Owner';
    teamMembers[1].agentDesignation = 'Broker Owner';
  }
  const isUhrig = listgenUserID === '100568';
  const isLisaMcCollum = listgenUserID === '100656';
  const isKristinArntz = listgenUserID === '100462';

  let fullName = `${listgenUserFirstName} ${listgenUserLastName}`;
  let secondAgent = 'agent';
  if (isMemberOfTeam) {
    secondAgent = `${teamMembers[1].firstName} ${teamMembers[1].lastName}`;
  }
  let smallerNameAndPhoneFont = fullName.length > 18 || secondAgent.length > 18 ? true : false;
  let isVait = listgenUserID === '101001';

  const getExtraFooterImage = (listgenUserID: string) => {
    if (isScar) {
      return (
        <div className={classes.scarLogo}>
          <img src="https://pma-coverphotos.s3.amazonaws.com/scar-cover-logo.png" alt="Scar logo" />
        </div>
      );
    } else if (isJohnsons) {
      return (
        <div className={classes.johnsonsQrCode}>
          <img src="https://pma-coverphotos.s3.amazonaws.com/johnsons-qr-code.png" alt="Johnsons logo" />
        </div>
      );
    } else if (isUhrig) {
      return (
        <div className={classes.uhrigLogo}>
          <img src="https://pma-coverphotos.s3.amazonaws.com/bryan_uhrig_qr.png" alt="Bryan Uhrig logo" />
        </div>
      );
    } else if (listgenUserID === '100778') {
      return (
        <div className={classes.johnsonsQrCode}>
          <img src="https://pma-coverphotos.s3.amazonaws.com/david-kim-updated-qr.png" />
        </div>
      );
    } else if (isKristinArntz) {
      return (
        <div className={classes.kristinArntzCoverLogo}>
          <img src="https://pma-coverphotos.s3.amazonaws.com/kristin-arntz-cover-logo.png" alt="Kristin Arntz logo" />
        </div>
      );
    } else if (isLisaMcCollum) {
      return (
        <div className={classes.calganoHamiltonCoverLogo}>
          <img src="https://pma-coverphotos.s3.amazonaws.com/calgano-hamilton-logo.png" alt="Calgano Hamilton Logo" />
        </div>
      );
    } else if (isAnnAlbanese) {
      return (
        <div className={classes.globalLuxuryCbCoverLogo}>
          <img
            src="https://pma-coverphotos.s3.amazonaws.com/pres_circle_bronze_500x500px.png"
            alt="Global Luxury Coldwell Banker Logo"
          />
        </div>
      );
    } else if (listgenUserID === '101567') {
      return (
        <div className={classes.globalLuxuryCbCoverLogo}>
          <img
            src="https://pma-coverphotos.s3.amazonaws.com/INternational+Presidents+Elite+BlackSIL.png"
            alt="Global Luxury Coldwell Banker Logo"
          />
        </div>
      );
    } else {
      return null;
    }
  };
  const vaitFooter = (
    <div className={classes.vaitCoverInfo}>
      <div className={classes.vaitTransparentInnerContainer}>
        <div className={classes.vaitAgentInfoSection}>
          <div className={classes.vaitAgentInfoCol}>
            <div className={classes.vaitAgentAndPhone}>
              <p className={classnames(classes.vaitAgentName, classes.smallerNameAndPhone)}>
                {listgenUserFirstName} {listgenUserLastName}
              </p>
            </div>
            <div className={classes.vaitRow}>
              <p className={classnames(classes.vaitDesignation, classes.caps)}>Realtor </p>{' '}
              <p className={classes.vaitDesignationSpan}>-</p>
              <span className={classes.vaitMx2}></span>
              <p className={classes.vaitDesignation}>Broker Associate</p>
            </div>
            <div className={classes.vaitDrePhoneRow}>
              <div className={classes.vaitAgentPhoneNumber}>{phoneCircleDivider(mobilePhoneNo, fullName.length)}</div>
              <div className={classes.vaitDesignationAndDre}>
                <p className={classes.vaitDreWord}>DRE</p>
                <p className={classes.vaitDreNum}> # {agentLicenseNumber}</p>
              </div>
            </div>
          </div>

          <div className={classnames(classes.vaitAgentInfoBoxLogo)}>
            {specialBrokerageURL ? (
              <img
                className={classnames(classes[agentLogoStyles], isMemberOfTeam && classes.teamLogo)}
                src={specialBrokerageURL}
                alt={specialBrokerageURL}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  );

  let douglasFooter = (
    <div className={classnames(classes.qv4CoverPageInfo, classes.douglasInfo)}>
      <div className={classes.transparentInnerContainer}>
        <div className={classes.agentInfoSection}>
          <div className={classes.teamInfoCol}>
            <div className={classes.teamAgentContainer}>
              <div className={classes.teamAgentCol}>
                <div className={classes.teamAgentAndPhone}>
                  <div className={classnames(classes.teamAgentName, classes.largerAgentName)}>
                    {listgenUserFirstName} {listgenUserLastName}
                  </div>
                  <div className={classes.teamVerticalDivider} />
                  <div className={classnames(classes.teamAgentPhone, classes.largerPhoneNum)}>
                    {phoneCircleDivider(mobilePhoneNo, fullName.length)}
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.teamAgentContainer}>
              <div className={classes.teamAgentCol}>
                <div className={classes.teamAgentAndPhone}>
                  <p className={classes.teamAgentName}>Rachael Douglas & Associates</p>
                  <div className={classes.teamVerticalDivider} />
                  <div className={classes.teamDesignationAndDre}>
                    <div className={classes.dreRow}>
                      <p className={classes.dreWord}>dre</p>
                      <p className={classes.dreNum}> #{agentLicenseNumber}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={classnames(
              classes.agentInfoBoxLogo,
              brokerageName.includes('Keller Williams') && classes.longerAgentInfoBoxLogo,
              classes.agentLogoMr,
              classes.agentInfoBoxStyles
            )}
          >
            <img
              className={classnames(agentLogoStyles && classes[agentLogoStyles], classes.douglasSpecializedLogo)}
              src={specialBrokerageURL}
              alt={specialBrokerageURL}
            />
          </div>
        </div>
      </div>
    </div>
  );

  let isThreePerson;
  if (teamMembers && teamMembers[2] && listgenUserID !== '105795') {
    isThreePerson = (
      <div className={classnames(classes.qv4CoverPageInfo, classes.douglasInfo)}>
        <div className={classnames(classes.transparentInnerContainer, classes.threePersonInnerContainer)}>
          <div className={classnames(classes.privacyPolicy, classes.privacyPolicyCol)}>
            <p
              onClick={() => {
                legalModalFunc(true);
              }}
            >
              Privacy
            </p>
            <p
              onClick={() => {
                legalModalFunc(true);
              }}
            >
              Policy
            </p>
            {listgenUserID === '102712' && (
              <p className={classnames(classes.privacyPolicy, classes.privacyPolicyCol)}>
                <a href="https://kazemiteam.com" target="_blank" className={classes.customWebsiteLink} rel="noreferrer">
                  kazemiteam.com
                </a>
              </p>
            )}
          </div>

          <div className={classes.agentInfoSection}>
            <div className={classnames(classes.teamInfoCol, classes.zechInfoFooterRow)}>
              <div className={classnames(classes.teamAgentContainer)}>
                <div className={classes.teamAgentCol}>
                  <div className={classes.teamAgentAndPhone}>
                    <div
                      className={classnames(
                        classes.teamAgentName,
                        smallerNameAndPhoneFont && classes.smallerNameAndPhone
                      )}
                    >
                      {listgenUserFirstName} {listgenUserLastName}
                    </div>
                    <div className={classes.teamVerticalDivider} />
                  </div>
                  <div
                    className={classnames(
                      classes.teamAgentPhone,
                      smallerNameAndPhoneFont && classes.smallerNameAndPhone
                    )}
                  >
                    {phoneCircleDivider(mobilePhoneNo)}
                  </div>
                  <div className={classnames(classes.teamSubInfo)}>
                    <div className={classes.teamDesignationAndDre}>
                      <p
                        className={classnames(
                          classes.designationText,
                          smallerNameAndPhoneFont && classes.smallerDesignation
                        )}
                      >
                        {formatAgentDesignation(agentDesignation)}
                      </p>
                      <div className={classes.dreRow}>
                        <p className={classes.dreWord}>dre</p> <p className={classes.dreNum}>#{agentLicenseNumber}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classnames(classes.teamAgentContainer)}>
                <div className={classes.teamAgentCol}>
                  <div className={classes.teamAgentAndPhone}>
                    <p
                      className={classnames(
                        classes.teamAgentName,
                        smallerNameAndPhoneFont && classes.smallerNameAndPhone
                      )}
                    >
                      {teamMembers[1].firstName} {teamMembers[1].lastName}
                    </p>
                    <div className={classes.teamVerticalDivider} />
                  </div>
                  <div
                    className={classnames(
                      classes.teamAgentPhone,
                      smallerNameAndPhoneFont && classes.smallerNameAndPhone
                    )}
                  >
                    {phoneCircleDivider(teamMembers[1].phone)}
                  </div>
                  <div className={classnames(classes.teamSubInfo)}>
                    <div className={classes.teamDesignationAndDre}>
                      <p
                        className={classnames(
                          classes.designationText,
                          smallerNameAndPhoneFont && classes.smallerDesignation
                        )}
                      >
                        {formatAgentDesignation(teamMembers[1].agentDesignation)}
                      </p>
                      <div className={classes.dreRow}>
                        <p className={classes.dreWord}>dre</p>
                        <p className={classes.dreNum}> #{teamMembers[1].agentLicenseNumber}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={classnames(classes.teamAgentContainer)}>
                <div className={classes.teamAgentCol}>
                  <div className={classes.teamAgentAndPhone}>
                    <p
                      className={classnames(
                        classes.teamAgentName,
                        smallerNameAndPhoneFont && classes.smallerNameAndPhone
                      )}
                    >
                      {teamMembers[2].firstName} {teamMembers[2].lastName}
                    </p>
                    <div className={classes.teamVerticalDivider} />
                  </div>
                  <div
                    className={classnames(
                      classes.teamAgentPhone,
                      smallerNameAndPhoneFont && classes.smallerNameAndPhone
                    )}
                  >
                    {phoneCircleDivider(teamMembers[2].phone)}
                  </div>
                  <div className={classnames(classes.teamSubInfo)}>
                    <div className={classes.teamDesignationAndDre}>
                      <p
                        className={classnames(
                          classes.designationText,
                          smallerNameAndPhoneFont && classes.smallerDesignation
                        )}
                      >
                        {formatAgentDesignation(teamMembers[2].agentDesignation)}
                      </p>
                      <div className={classes.dreRow}>
                        <p className={classes.dreWord}>dre</p>
                        <p className={classes.dreNum}> #{teamMembers[2].agentLicenseNumber}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={classnames(
                classes.agentInfoBoxLogo,
                brokerageName.includes('Keller Williams') && classes.longerAgentInfoBoxLogo,
                classes.agentLogoMr,
                classes.agentInfoBoxStyles
              )}
            >
              <img
                className={classnames(agentLogoStyles && classes[agentLogoStyles], classes.douglasSpecializedLogo)}
                src={specialBrokerageURL}
                alt={specialBrokerageURL}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={classnames(classes.agentInfoFooterContainer, classes[footerBrokerageStyle])}>
      {isVait ? (
        vaitFooter
      ) : listgenUserID === '100528' ? (
        { douglasFooter }
      ) : isThreePerson ? (
        isThreePerson
      ) : (
        <div
          className={classnames(
            classes.qv4CoverPageInfo,
            isMemberOfTeam ? classes['team'] : ''
            // listgenUserID === '100190' ? classes.coverInfoThreeTeam : ''
          )}
        >
          {/* team: isMemberOfTeam,
            coverInfoThreeTeam: listgenUserID === '100190', */}
          <div className={classes.transparentInnerContainer}>
            <div
              className={classes.privacyPolicy}
              onClick={() => {
                legalModalFunc(true);
              }}
            >
              <p>Privacy Policy</p>
            </div>
            <div className={classnames(classes.agentInfoSection, isMemberOfTeam ? classes.team : '')}>
              {isMemberOfTeam ? (
                <div className={classes.teamInfoCol}>
                  <div
                    className={classnames(
                      classes.teamAgentContainer
                      // listgenUserID === '100190' && classes.smallerHeight
                    )}
                  >
                    <div className={classes.teamAgentCol}>
                      <div className={classes.teamAgentAndPhone}>
                        <div
                          className={classnames(
                            classes.teamAgentName,
                            smallerNameAndPhoneFont && classes.smallerNameAndPhone
                          )}
                        >
                          {listgenUserFirstName} {listgenUserLastName}
                        </div>
                        <div className={classes.teamVerticalDivider} />
                        <div
                          className={classnames(
                            classes.teamAgentPhone,
                            smallerNameAndPhoneFont && classes.smallerNameAndPhone
                          )}
                        >
                          {phoneCircleDivider(mobilePhoneNo)}
                        </div>
                      </div>
                      <div
                        className={classnames(
                          classes.teamSubInfo
                          // listgenUserID === '100190' && classes.reducedTeamSubInfoHeight
                        )}
                      >
                        <div className={classes.teamDesignationAndDre}>
                          <p
                            className={classnames(
                              classes.designationText,
                              smallerNameAndPhoneFont && classes.smallerDesignation
                            )}
                          >
                            {formatAgentDesignation(agentDesignation)}
                          </p>
                          <div className={classes.dreRow}>
                            <p className={classes.dreWord}>dre</p>{' '}
                            <p className={classes.dreNum}>#{agentLicenseNumber}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={classnames(classes.teamAgentContainer)}>
                    <div className={classes.teamAgentCol}>
                      <div className={classes.teamAgentAndPhone}>
                        <p
                          className={classnames(
                            classes.teamAgentName,
                            smallerNameAndPhoneFont && classes.smallerNameAndPhone
                          )}
                        >
                          {teamMembers[1].firstName} {teamMembers[1].lastName}
                        </p>
                        <div className={classes.teamVerticalDivider} />
                        {listgenUserID !== '100027' && (
                          <div
                            className={classnames(
                              classes.teamAgentPhone,
                              smallerNameAndPhoneFont && classes.smallerNameAndPhone
                            )}
                          >
                            {phoneCircleDivider(teamMembers[1].phone)}
                          </div>
                        )}
                      </div>
                      <div
                        className={classnames(
                          classes.teamSubInfo
                          // listgenUserID === '100190' && classes.reducedTeamSubInfoHeight
                        )}
                      >
                        <div className={classes.teamDesignationAndDre}>
                          <p
                            className={classnames(
                              classes.designationText,
                              smallerNameAndPhoneFont && classes.smallerDesignation
                            )}
                          >
                            {formatAgentDesignation(teamMembers[1].agentDesignation)}
                          </p>
                          <div className={classes.dreRow}>
                            <p className={classes.dreWord}>dre</p>
                            <p className={classes.dreNum}> #{teamMembers[1].agentLicenseNumber}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className={classes.agentInfoCol}>
                  <div className={classes.agentAndPhone}>
                    {listgenUserID === '100988' ? (
                      <p
                        className={classnames(
                          classes.agentName,
                          smallerNameAndPhoneFont && classes.smallerNameAndPhone
                        )}
                      >
                        Dianne & Brianna {listgenUserLastName}
                      </p>
                    ) : (
                      <p
                        className={classnames(
                          classes.agentName,
                          smallerNameAndPhoneFont && classes.smallerNameAndPhone
                        )}
                      >
                        {listgenUserFirstName} {listgenUserLastName}
                      </p>
                    )}
                    <div className={classes.verticalDivider} />
                    <div
                      className={classnames(classes.agentPhone, smallerNameAndPhoneFont && classes.smallerNameAndPhone)}
                    >
                      {phoneCircleDivider(mobilePhoneNo, fullName.length)}
                    </div>
                  </div>

                  {isScar ? (
                    <div className={classes.designationAndDre}>
                      <p className={classes.agentDesignation}>Realtors</p>

                      <p className={classes.dreWord}>DRE</p>
                      <p className={classes.dreNum}> # 01182792 </p>
                      <p> / </p>
                      <p className={classes.dreWord}>DRE</p>
                      <p className={classes.dreNum}> # 01050912</p>
                    </div>
                  ) : isJohnsons ? (
                    <div className={classes.designationAndDre}>
                      <p className={classes.agentDesignation}>Realtors</p>
                      <p className={classes.dreWord}>DRE</p>
                      <p className={classes.dreNum}> # 00947199 </p>
                      <p> / </p>
                      <p className={classes.dreWord}>DRE</p>
                      <p className={classes.dreNum}> # 01943572</p>
                    </div>
                  ) : (
                    <div className={classes.designationAndDre}>
                      <p
                        className={classnames(
                          classes.agentDesignation,
                          smallerNameAndPhoneFont && classes.smallerAgentDesignation
                        )}
                      >
                        {formatAgentDesignation(agentDesignation)}
                      </p>
                      <div className={classes.dreWord}>
                        <p>DRE</p> <p className={classes.dreNum}> # {agentLicenseNumber}</p>
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div
                className={classnames(
                  classes.agentInfoBoxLogo,
                  brokerageName.includes('Keller Williams') && classes.longerAgentInfoBoxLogo,

                  (isScar || isUhrig || isLisaMcCollum || isAnnAlbanese || listgenUserID === '101567') &&
                    classes.agentLogoMr,
                  classes.agentInfoBoxStyles
                )}
              >
                {' '}
                <img
                  className={classnames(
                    agentLogoStyles && classes[agentLogoStyles],
                    isMemberOfTeam && classes.teamLogo
                  )}
                  src={specialBrokerageURL}
                  alt={specialBrokerageURL}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default QVFourCoverPageInfo;
