import classes from '../../page.module.css';
import classnames from 'classnames';
import React from 'react';

interface PageTrackerProps {
  activeNum: number;

  changeStepFunc: (step: number) => void;
}

const PageTracker: React.FC<PageTrackerProps> = ({ activeNum, changeStepFunc }): JSX.Element => {
  return (
    <div
      className={classnames(
        activeNum === 2 || activeNum === 3 ? classes.pageTrackerContainer2 : classes.pageTrackerContainer
      )}
    >
      <div className={classnames(classes.pageTracker)}>
        <div className={classnames(classes.pageTrackerNumBorder, activeNum === 1 && classes.activeTracker)}>
          <div
            className={classes.pageTrackerNum}
            onClick={() => {
              changeStepFunc(1);
            }}
          >
            1
          </div>
        </div>
        <div className={classes.pageTrackerDivider} />
        <div className={classnames(classes.pageTrackerNumBorder, activeNum === 2 && classes.activeTracker)}>
          <div
            className={classes.pageTrackerNum}
            onClick={() => {
              changeStepFunc(2);
            }}
          >
            2
          </div>
        </div>
        <div className={classes.pageTrackerDivider} />
        <div className={classnames(classes.pageTrackerNumBorder, activeNum === 3 && classes.activeTracker)}>
          <div
            className={classes.pageTrackerNum}
            onClick={() => {
              changeStepFunc(3);
            }}
          >
            3
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageTracker;
