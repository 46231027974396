import React from 'react';
import classes from '../page.module.css';
import PageTracker from './PropInfoCommon/PageTracker';
import classnames from 'classnames';
import CoverPageInfo from './PropInfoCommon/CoverPageInfo';
import { HOData } from '../../types/hoDataTypes';
import {
  getNoteGreetingTextV4,
  lc,
  getAgentSignoff,
  IOrWe,
  AmOrAre,
  MeOrUs,
  ImOrWere,
} from '../../util/propertyUtil.jsx';
import PMACover from '../../homeownerImages/cover.png';

interface FourthPageProps {
  homeownerData: HOData;
  legalModalFunc: (open: any) => void;
}

const FourthPropInfoPage: React.FC<FourthPageProps> = ({ homeownerData, legalModalFunc }): JSX.Element => {
  const { mailingDetails, changeDetails, mobilePhoneNo, isMemberOfTeam, areaID, brokerageName } = homeownerData;

  let { MailAddress, MailCity, MailState } = mailingDetails;
  const { SiteNumber, SiteStreet } = mailingDetails;

  let hoName = getNoteGreetingTextV4(homeownerData.changeDetails, '&');
  let headerBrokerageStyle = '';
  if (brokerageName.includes("Sotheby's")) {
    headerBrokerageStyle = 'headerLGBlue';
  } else if (
    (brokerageName.includes('Over') && brokerageName.includes('Moon')) ||
    brokerageName.includes('Compass') ||
    brokerageName.includes('Alemann & Associates')
  ) {
    headerBrokerageStyle = 'headerBlack';
  } else if (brokerageName.includes('Berkshire Hathaway')) {
    headerBrokerageStyle = 'headerPurple';
  } else if (brokerageName.includes('Village Properties')) {
    headerBrokerageStyle = 'headerHarbor';
  } else if (brokerageName.includes('Town')) {
    headerBrokerageStyle = 'headerLightBlue';
  }
  return (
    <div className={classnames(classes.propertyInfoPage, classes.fourthPage)}>
      <div className={classes.propertyInfoTopRow}>
        <div className={classnames(classes.propertyInfoContentContainer, classes.fourthContentContainer)}>
          <div className={classes.fourthRow}>
            <div className={classes.fourthCol1}>
              <div className={classes.fourthDescriptionContainer}>
                <div className={classnames(classes.fourthTitle, classes[headerBrokerageStyle])}>
                  Thank you, Neighbor
                </div>
                <div className={classes.fourthParagraphSection}>
                  <p className={classes.fourthDescriptionText}>Thank you for your home valuation request.</p>
                </div>
                <div className={classes.fourthParagraphSection}>
                  <p className={classes.fourthDescriptionText}>
                    {IOrWe(isMemberOfTeam, true)} will get back to you shortly with a comprehensive review of your
                    home’s value.
                  </p>
                </div>

                <div className={classes.fourthParagraphSection}>
                  <p className={classes.fourthDescriptionText}>
                    And of course, if you have any questions or would like to further discuss, please give{' '}
                    {MeOrUs(isMemberOfTeam)} a call or text any time.
                  </p>
                </div>
                <div className={classes.fourthParagraphSection}>
                  <p className={classes.fourthDescriptionText}>
                    {IOrWe(isMemberOfTeam, true)} look forward to sharing more!
                  </p>
                </div>
                <div className={classes.fourthParagraphSection}>
                  <p className={classes.fourthAgentName}>— {getAgentSignoff(homeownerData)}</p>
                </div>
              </div>
            </div>
            <div className={classes.fourthCol2}>
              <img src={`https://pma-cover-thumbnails.s3.amazonaws.com/${areaID}.jpg`} alt="Pma collage" />
            </div>
          </div>
        </div>
      </div>
      <CoverPageInfo homeownerData={homeownerData} legalModalFunc={legalModalFunc} />
    </div>
  );
};

export default FourthPropInfoPage;
