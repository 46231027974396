import { useEffect, useContext } from 'react';
import { RouteSlugType } from '../../types/dataTypes';
import { useActions } from '../../store/Actions';
import { DataContext, DataContextDispatch, DispatchAction } from '../../store/Context';
import { fetchHomeownerDetails } from '../../client/HOService';
import { HOData } from '../../types/hoDataTypes';
import { useHistory } from 'react-router-dom';
import React from 'react';

interface PropertyInfoWrapperProps {
  children: React.ReactElement;
  slugParams: RouteSlugType;
}

const PropertyInfoWrapper: React.FC<PropertyInfoWrapperProps> = ({ children, slugParams }) => {
  const homeOwnerURLID: string = slugParams.slug;

  const history = useHistory();

  const storeData = useContext(DataContext);
  const dispatch = useContext(DataContextDispatch);
  const actions = useActions(dispatch);
  useEffect(() => {
    actions.setLoading(true);
    homeOwnerURLID &&
      fetchHomeownerDetails({ homeOwnerURLID })
        .then((apiResponse) => {
          actions.updateQRValidity(true);
          actions.updateHOData(apiResponse.data as HOData);
        })
        .catch((error) => {
          console.error('Error validating qr code:', error);
          actions.updateQRValidity(false);
          history.push('/invalid');
        })
        .finally(() => {
          actions.setLoading(false);
        });
  }, [homeOwnerURLID]);

  if (storeData.isLoading) {
    return <p>Loading...</p>;
  }

  if (storeData.isValidQRCode) {
    return children;
  }

  return null;
};

export default PropertyInfoWrapper;
