'use client';
import React from 'react';
import { DataContext } from '../../store/Context';
import { HOData } from '../../types/hoDataTypes';
import classes from './PropertyInfo.module.css';
import { useContext } from 'react';
import PropertyInfoPage from '../../pages';

type PropertyInfoProps = {};

export const PropertyInfo: React.FC<PropertyInfoProps> = (): JSX.Element => {
  const storeData = useContext(DataContext);

  return (
    <div className={classes.propertyInfo}>
      {/*   <div>Wow !! property info validated</div>
      <div>QR Code Path: {(storeData.hoData as HOData).homeOwnerURLQRCodePath}</div> */}
      <PropertyInfoPage
      //hoData={storeData as any}
      />
    </div>
  );
};
